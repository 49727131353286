import {BranchError, DeepLinkData} from 'branch-sdk';
import {noop} from 'lodash';

/* eslint-disable */
// this was provided by Branch
// @ts-ignore
(function (b, r, a, n, c, h, _, s, d, k) {
  if (!b[n] || !b[n]._q) {
    for (; s < _.length; ) c(h, _[s++]);
    // @ts-ignore
    d = r.createElement(a);
    // @ts-ignore
    d.async = 1;
    // @ts-ignore
    d.src = 'https://cdn.branch.io/branch-latest.min.js';
    // @ts-ignore
    k = r.getElementsByTagName(a)[0];
    // @ts-ignore
    k.parentNode.insertBefore(d, k);
    b[n] = h;
  }
})(
  window,
  document,
  'script',
  'branch',
  function (b, r) {
    b[r] = function () {
      b._q.push([r, arguments]);
    };
  },
  {_q: [], _v: 1},
  'addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode'.split(
    ' '
  ),
  0
);
/* eslint-enable */

type BranchData = {
  key: string;
  defaultURL: string;
  tags: string[];
  data: object;
};

type Branch = {
  init: (string) => void;
  sendSMS: (
    phone: string,
    linkData: DeepLinkData,
    options?: {make_new_link?: boolean},
    callback?: (err: BranchError) => void
  ) => void;
  link: (linkData: DeepLinkData, callback: (err: BranchError, link: string | null) => void) => void;
  setBranchViewData: (viewData: DeepLinkData) => void;
};

declare global {
  interface Window {
    BranchData: BranchData;
    branch: Branch;
  }
}

const branchData: BranchData = window.BranchData;
const joinPageData = {
  $desktop_url: branchData.defaultURL,
  $android_url: branchData.defaultURL,
  $windows_phone_url: branchData.defaultURL,
  $blackberry_url: branchData.defaultURL,
  $fire_url: branchData.defaultURL,
  ...branchData.data,
};

let branchInitizalized = false;
const initBranch = () => {
  if (!branchInitizalized) {
    branchInitizalized = true;
    window.branch.init(branchData.key);
  }
};

document.querySelectorAll('.text-app-link-form').forEach(function (elem) {
  elem.addEventListener('submit', function (e) {
    initBranch();
    e.preventDefault();

    // @ts-ignore
    const mobile = this.mobile.value;

    window.branch.sendSMS(
      mobile,
      {
        feature: 'referral-sms',
        tags: branchData.tags,
        data: joinPageData,
      },
      {},
      noop
    );

    // @ts-ignore
    this.mobile.value = '';
    // @ts-ignore
    this.submit.innerHTML = '<i class="rm rm-raise-check"></i>';

    setTimeout(() => {
      // @ts-ignore
      this.submit.innerHTML = 'Send';
    }, 4000);
  });
});

if (location.pathname.substring(0, 5) === '/join') {
  initBranch();

  window.branch.link(
    {
      feature: 'app-store-badge',
      tags: branchData.tags,
      data: joinPageData,
    },
    (err, url) => {
      if (!err) {
        const link = document.querySelector('.app-banner-link');

        // null guard makes TS happy
        if (link) {
          link.setAttribute('href', url || '');
        }
      }
    }
  );

  window.branch.setBranchViewData({
    feature: 'app-store-banner',
    tags: branchData.tags,
    data: joinPageData,
  });
}
